import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/preview-react/pill/Pill.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/preview-react/pill" storybook="https://workday.github.io/canvas-kit/?path=/story/preview-pill--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=30877-34302&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-preview-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/440ba967858c09dbc94c183402661226/536c7/component-anatomy-pills.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.5945945945946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of Pill component with annotation markers.",
              "title": "Image of Pill component with annotation markers.",
              "src": "/static/440ba967858c09dbc94c183402661226/50383/component-anatomy-pills.png",
              "srcSet": ["/static/440ba967858c09dbc94c183402661226/1efb2/component-anatomy-pills.png 370w", "/static/440ba967858c09dbc94c183402661226/50383/component-anatomy-pills.png 740w", "/static/440ba967858c09dbc94c183402661226/536c7/component-anatomy-pills.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Background and stroke element containing all other elements within this component`}</li>
        <li parentName="ol"><strong parentName="li">{`Pill Label:`}</strong>{` Describes the Pill meaning or input.`}</li>
        <li parentName="ol"><strong parentName="li">{`Icon (Optional):`}</strong>{` Displayed before the Pill label to provide an additional supporting visual.
Icons displayed after the label are reserved for Removable Pills to further convey the action.
For Removable Pills, the icon becomes the click or touch target.`}</li>
        <li parentName="ol"><strong parentName="li">{`Counter:`}</strong>{` Number appearing after the label used to show the count of something.`}</li>
        <li parentName="ol"><strong parentName="li">{`Counter Container:`}</strong>{` Contrasting background that houses the Counter.`}</li>
        <li parentName="ol"><strong parentName="li">{`Avatar (Optional):`}</strong>{` Circular graphic or photo that appears to the left of the Pill label.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`Pills are used to visually label objects on a page for quick recognition. They’re offered as both
static, read-only and interactive elements. They allow users to input a section, filter a list or
table, or label information to help with scanning and organization.`}</p>
      <h4 {...{
        "id": "pills-with-icon-or-avatar"
      }}>{`Pills with Icon or Avatar`}</h4>
      <ul>
        <li parentName="ul">{`There are considerations specifically for leading and trailing icons.`}</li>
        <li parentName="ul">{`All leading elements (icons or avatars) are intended to be descriptive, helping support the label.
Do not rely on the leading element to indicate the interaction behavior.`}</li>
        <li parentName="ul">{`All trailing elements are reserved for removable Pills and Pills with count. The X icon indicates
the expected interaction, and appears after the label to assure the user is aware what is being
removed. The count appears after indicating the action is directly related to the count of that
label.`}</li>
      </ul>
      <h4 {...{
        "id": "pills-that-are-removable"
      }}>{`Pills that are Removable`}</h4>
      <ul>
        <li parentName="ul">{`Pills that are removable display the X icon following after the label.`}</li>
        <li parentName="ul">{`Removable pills have a smaller, more specific focus state and click target to be more intentional
about its action and to avoid unintended removal of a Pill.`}</li>
        <li parentName="ul">{`Pills are aligned either left or right and the flow of the removal will move existing Pills
towards that alignment. If a Pill is removed, there should be a way for the user to add the Pill
again.`}</li>
      </ul>
      <h4 {...{
        "id": "pills-used-as-a-filter"
      }}>{`Pills Used as a Filter`}</h4>
      <ul>
        <li parentName="ul">{`Filters appearing as a Pill allow for the user to scan and organize their filters more easily.`}</li>
        <li parentName="ul">{`Pills fit the type of filter into a small space and allows users to quickly see if the filter is
applied and/or remove it.`}</li>
        <li parentName="ul">{`Pills add progressive disclosure to your filters by increasing learnability and reducing filter
errors.`}</li>
      </ul>
      <h4 {...{
        "id": "responsive-treatment"
      }}>{`Responsive Treatment`}</h4>
      <p>{`Pills should fill their container, inline. For smaller screens and smaller containers wrap Pills so
they stack to multiple lines when necessary.`}</p>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When To Use`}</h3>
      <ul>
        <li parentName="ul">{`To show and label selected inputs that can be added and removed.`}</li>
        <li parentName="ul">{`Pills are meant for displaying in a small space as a group. Use them to communicate labels,
selections, and categories.`}</li>
        <li parentName="ul">{`Pills can increase the amount of visual noise on a page so use them in moderation.`}</li>
        <li parentName="ul">{`Pills can be used to input complex information in a compact Form Field. Input Pills allow users to
verify input by converting content or text into a Pill. They can produce suggested responses, such
as in a Workday Prompt.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When To Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`For static labels that communicate a status use the Status Indicator component.`}</li>
        <li parentName="ul">{`For actions that affect anything else use a `}<a parentName="li" {...{
            "href": "/components/buttons/button#tab=android"
          }}>{`Button`}</a>{`.
Buttons are expected to appear consistently and with familiar calls to action, Pills should appear
dynamically as a group of multiple interactive elements.`}</li>
        <li parentName="ul">{`For labels that don’t appear in multiples and in a group, consider using simple body text or a
header.`}</li>
        <li parentName="ul">{`If you have more content than a simple label to display, consider using simple body text or a
header.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`If the content exceeds the max-width, an ellipses must appear to communicate overflow. Full
content will appear on hover or focus within a Tooltip.`}</li>
        <li parentName="ul">{`Pills are intended to be placed on white UI backgrounds. Consider contrast requirements when
placing elsewhere.`}</li>
        <li parentName="ul">{`The click and touch targets for Pills are expanded beyond the Pill container to better support
responsive, touch screen devices.`}</li>
        <li parentName="ul">{`Removable Pills have an intentionally small, more specific touch target to avoid accidental Pill
removal.`}</li>
        <li parentName="ul">{`Pill names must be clear and distinctive from one another. Avoid using many Pills on the page with
generic identical names. This places additional burden on users to correctly understand the
surrounding context for each of the identical pills on the screen.`}</li>
        <li parentName="ul">{`When a Pill is disabled, it typically will not appear in the keyboard focus order. However, this
can make it more difficult for users to discover, especially with reduced contrast. If a disabled
Pill is blocking users' progress through a flow, it's be beneficial to add it back into keyboard
focus order. Also, when a lengthy, disabled Pill is truncated, then keyboard users will not have
access to the full length text if they cannot focus it.`}</li>
        <li parentName="ul">{`Screen-readers must announce the entire Pill Label, regardless of width or truncation.`}</li>
      </ul>
      <p>{`For more accessibility considerations, refer to the
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/overview"
        }}>{`Accessibility Guide`}</a>{`.`}</p>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Pill labels should be kept simple, short and concise. They’re great for organizing and providing
cues for what’s been selected, but you can’t fit a lot into that space. Max-width is defined by
the width of the parent container of the Pill plus padding.`}</li>
        <li parentName="ul">{`If the content exceeds the max-width, an ellipses must appear to communicate overflow.`}</li>
        <li parentName="ul">{`Full content will appear on hover in a Tooltip. If you have more content than a simple label to
display, use another component recommended above under guidelines.`}</li>
        <li parentName="ul">{`When writing content for Pill Labels, refer to our `}<a parentName="li" {...{
            "href": "/guidelines/content/overview"
          }}>{`Content Style Guide`}</a>{`.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?type=design&node-id=7263-3942&mode=design&t=bxuNrp4RWhbBNdjx-11" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/78d8a3d40ad98a996583a90fd1e717ad/0ddab/pills-anatomy-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAACE4AAAhOAFFljFgAAAAoklEQVR42n2QywrCMBBF/f//ciG6sBtbUws+SjelFPqgzSTNMX0IVdFLhjD3Zg5hNnzKufnOKyia2brmYIe33BU1bnyznvHafAE78flAE6W04YMR024VTVnRac3gwa1oapXRh9kf4GIOuwSbFkQnRRwndL2gDiXBMeKWpvTaEKozwf5OdqnHwen8/qEHj7kWwRjjW4eI8SVYa5deJs+81rDSE2F8N5K9tTayAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Pill's anatomy ",
                "title": "Image of a Pill's anatomy ",
                "src": "/static/78d8a3d40ad98a996583a90fd1e717ad/50383/pills-anatomy-mobile.png",
                "srcSet": ["/static/78d8a3d40ad98a996583a90fd1e717ad/1efb2/pills-anatomy-mobile.png 370w", "/static/78d8a3d40ad98a996583a90fd1e717ad/50383/pills-anatomy-mobile.png 740w", "/static/78d8a3d40ad98a996583a90fd1e717ad/0ddab/pills-anatomy-mobile.png 2220w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Background and border holds all elements within a Pill.`}</li>
          <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Describes the Pill meaning or input, ideally a single word or two.`}</li>
          <li parentName="ol"><strong parentName="li">{`Leading Area (Optional):`}</strong>{` An avatar or flag displayed before the label.`}</li>
          <li parentName="ol"><strong parentName="li">{`Trailing Area (Optional) :`}</strong>{` Icons placed after the label are meant to convey the intended
interaction of the pill, with the exception of the Count Pills. Trailing icon can be X, check,
plus or counter.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states"
        }}>{`Interaction States`}</h2>
        <p>{`Pills support Inactive, Pressed, and Disabled States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/974da2c2f7d8e6374e8989e0bc65e7a5/922e6/pills-states-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Pill's states",
                "title": "Image of a Pill's states",
                "src": "/static/974da2c2f7d8e6374e8989e0bc65e7a5/50383/pills-states-mobile.png",
                "srcSet": ["/static/974da2c2f7d8e6374e8989e0bc65e7a5/1efb2/pills-states-mobile.png 370w", "/static/974da2c2f7d8e6374e8989e0bc65e7a5/50383/pills-states-mobile.png 740w", "/static/974da2c2f7d8e6374e8989e0bc65e7a5/922e6/pills-states-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "variations"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "default"
        }}>{`Default`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b76993334f1c2b4579c743ba2a88e0c/922e6/pills-default-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a default pill",
                "title": "Image of a default pill",
                "src": "/static/2b76993334f1c2b4579c743ba2a88e0c/50383/pills-default-mobile.png",
                "srcSet": ["/static/2b76993334f1c2b4579c743ba2a88e0c/1efb2/pills-default-mobile.png 370w", "/static/2b76993334f1c2b4579c743ba2a88e0c/50383/pills-default-mobile.png 740w", "/static/2b76993334f1c2b4579c743ba2a88e0c/922e6/pills-default-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`By default a Pill is interactive, however the resulting interaction may vary based on the location
or context of the pill. For example, Pills navigate a user to more details related to the label of
the pill. Pills within prompts or inputs open the prompt allowing further interaction.`}</p>
        <h3 {...{
          "id": "size"
        }}>{`Size`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c318def9bbc18d834bd35b7fe6cf2123/922e6/pills-size-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a the different pill sizes",
                "title": "Image of a the different pill sizes",
                "src": "/static/c318def9bbc18d834bd35b7fe6cf2123/50383/pills-size-mobile.png",
                "srcSet": ["/static/c318def9bbc18d834bd35b7fe6cf2123/1efb2/pills-size-mobile.png 370w", "/static/c318def9bbc18d834bd35b7fe6cf2123/50383/pills-size-mobile.png 740w", "/static/c318def9bbc18d834bd35b7fe6cf2123/922e6/pills-size-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "medium"
        }}>{`Medium`}</h4>
        <p>{`This is the default size for pills and should be used within all inputs where pills are utilized. An
additional 4pt of tappable top and bottom margins are added to maintain a 48pt tap target.`}</p>
        <h4 {...{
          "id": "small"
        }}>{`Small`}</h4>
        <p>{`The small pill is used for tasks that are supplementary to the main task. For example, displaying
filtered parameters in relation to primary content. This stage of filtering is supplementary to the
main task. The small size pill variant is used to reinforce the hierarchy of the page by adding
emphasis to more important elements. Small pills maintain an additional 8pt top and bottom margin to
ensure a 48pt tap target.`}</p>
        <h3 {...{
          "id": "filtered"
        }}>{`Filtered`}</h3>
        <p>{`Filtered Pills are the activated version of a pill. These pills are differentiated from default
pills by the addition of a checkmark or X icon, depending on the use case.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/481f1a4801f098883b0a919efb66d175/922e6/pills-filtered-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of filtered pills",
                "title": "Image of filtered pills",
                "src": "/static/481f1a4801f098883b0a919efb66d175/50383/pills-filtered-mobile.png",
                "srcSet": ["/static/481f1a4801f098883b0a919efb66d175/1efb2/pills-filtered-mobile.png 370w", "/static/481f1a4801f098883b0a919efb66d175/50383/pills-filtered-mobile.png 740w", "/static/481f1a4801f098883b0a919efb66d175/922e6/pills-filtered-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`The checkmark is meant to be used when all filters are displayed alongside each other, with the
ability to quickly turn a filter on or off . The checkmark identifies the active filters. The
filtered pills with the X icons are meant to be used when displaying only those active filters.
Activation of filters would typically be a part of a larger flow. The X allows the user to quickly
remove the active filter resulting in the pill disappearing from the screen.`}</p>
        <h3 {...{
          "id": "leading-area"
        }}>{`Leading Area`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/83d1a582fe966a2a89ef00eaea0ed552/922e6/pills-leading-area-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of leading area options for pills",
                "title": "Image of leading area options for pills",
                "src": "/static/83d1a582fe966a2a89ef00eaea0ed552/50383/pills-leading-area-mobile.png",
                "srcSet": ["/static/83d1a582fe966a2a89ef00eaea0ed552/1efb2/pills-leading-area-mobile.png 370w", "/static/83d1a582fe966a2a89ef00eaea0ed552/50383/pills-leading-area-mobile.png 740w", "/static/83d1a582fe966a2a89ef00eaea0ed552/922e6/pills-leading-area-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`All leading elements are intended to be descriptive, helping support the label. Do not rely on the
leading element to indicate the interaction behavior. Trailing elements are for interaction
behavior.`}</p>
        <h4 {...{
          "id": "avatar"
        }}>{`Avatar`}</h4>
        <p>{`A XXS avatar is displayed before the label.`}</p>
        <h4 {...{
          "id": "flag"
        }}>{`Flag`}</h4>
        <p>{`Coming Soon…`}</p>
        <h3 {...{
          "id": "trailing-area"
        }}>{`Trailing Area`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/63786a0b2209df13c8e3d7da80abdc44/922e6/pills-trailing-area-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of trailing options for pills",
                "title": "Image of trailing options for pills",
                "src": "/static/63786a0b2209df13c8e3d7da80abdc44/50383/pills-trailing-area-mobile.png",
                "srcSet": ["/static/63786a0b2209df13c8e3d7da80abdc44/1efb2/pills-trailing-area-mobile.png 370w", "/static/63786a0b2209df13c8e3d7da80abdc44/50383/pills-trailing-area-mobile.png 740w", "/static/63786a0b2209df13c8e3d7da80abdc44/922e6/pills-trailing-area-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Elements displayed after the label are meant to convey the expected interaction of the pill, with
the exception of the count variant.`}</p>
        <h4 {...{
          "id": "x-icon"
        }}>{`X Icon`}</h4>
        <p>{`These Pills display an X icon after the label conveying they can be removed from the page. If a Pill
is to be removed, there should always be a way for the user to add the Pill again. These should
never be used for destructive actions`}</p>
        <h4 {...{
          "id": "plus-icon"
        }}>{`Plus Icon`}</h4>
        <p>{`Pills with the plus icon are used to add items to a parent container. They can also be used in
filtering, conveying the filter can be activated.`}</p>
        <h4 {...{
          "id": "check-icon"
        }}>{`Check Icon`}</h4>
        <p>{`Pills with a checkmark are only used with filtered pills. The checkmark conveys the filter
attributed to the pill is active or enabled.`}</p>
        <h4 {...{
          "id": "count"
        }}>{`Count`}</h4>
        <p>{`Count pills display a number at the end of the pill. This number directly correlates with the pill
label. While the number can be as large as possible the count within the pill has a limit of 99, any
number larger than that will be displayed as 99+.`}</p>
        <h3 {...{
          "id": "non-interactive"
        }}>{`Non-Interactive`}</h3>
        <p>{`A non-interactive element used to display information about an object.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d4919dc664481cd575f95225562f6017/922e6/pills-non-interactive-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a non-interactive pill",
                "title": "Image of a non-interactive pill",
                "src": "/static/d4919dc664481cd575f95225562f6017/50383/pills-non-interactive-mobile.png",
                "srcSet": ["/static/d4919dc664481cd575f95225562f6017/1efb2/pills-non-interactive-mobile.png 370w", "/static/d4919dc664481cd575f95225562f6017/50383/pills-non-interactive-mobile.png 740w", "/static/d4919dc664481cd575f95225562f6017/922e6/pills-non-interactive-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Non-interactive pills do not truncate and instead wrap to multiple lines; however, try to keep Pill
text as succinct as possible - between 2-7 words or wrap no more than 2 lines.`}</p>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <p>{`Pills are used to visually label objects on a page for quick recognition. They’re offered as both
read-only and interactive elements. They allow users to input a section, filter, or label
information to help with scanning and organization.`}</p>
        <ul>
          <li parentName="ul">{`Interactive Pills should always truncate, never wrapping to multiple lines.`}</li>
          <li parentName="ul">{`Non-interactive Pills should not truncate and can wrap to multiple lines.`}</li>
          <li parentName="ul">{`The max-width of a pill is 600pt/dp, or up to the screen margins of the device.`}</li>
          <li parentName="ul">{`Pill groups can stack to multiple lines when necessary.`}</li>
          <li parentName="ul">{`Use pills in moderation, as they can increase the visual noise of a page.`}</li>
          <li parentName="ul">{`Ensure a minimum tap target height of 48dp to ensure easy interaction. This means adding an
additional 4dp of tappable top and bottom padding to the medium sized Pills and 8dp for small
Pills.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp for removal of the Pill." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/31e95bffe48e0f5fd2a143f76df7a208/8ce52/component-do-taptarget-pills-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Removable Pill with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Removable Pill with a minimum tap target of 48dp.",
                    "src": "/static/31e95bffe48e0f5fd2a143f76df7a208/8ce52/component-do-taptarget-pills-mobile.png",
                    "srcSet": ["/static/31e95bffe48e0f5fd2a143f76df7a208/1efb2/component-do-taptarget-pills-mobile.png 370w", "/static/31e95bffe48e0f5fd2a143f76df7a208/8ce52/component-do-taptarget-pills-mobile.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Close Icon might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/e5400295c1a6bfa25f6a02ad1bc11564/8ce52/component-dont-taptarget-pills-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Link Pill without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Link Pill without a minimum tap target of 48dp.",
                    "src": "/static/e5400295c1a6bfa25f6a02ad1bc11564/8ce52/component-dont-taptarget-pills-mobile.png",
                    "srcSet": ["/static/e5400295c1a6bfa25f6a02ad1bc11564/1efb2/component-dont-taptarget-pills-mobile.png 370w", "/static/e5400295c1a6bfa25f6a02ad1bc11564/8ce52/component-dont-taptarget-pills-mobile.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <ul>
          <li parentName="ul">{`Account for this interaction buffer by spacing multiple pills with 8dp padding between, above, and
below each pill in a group.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To visually label applied inputs.`}</li>
          <li parentName="ul">{`To aid selection of inputs`}</li>
          <li parentName="ul">{`To imply further interaction on individual items within groups`}</li>
          <li parentName="ul">{`To visually show filtered parameters`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`For static labels that communicate a status, use a Status Indicator.`}</li>
          <li parentName="ul">{`For any sort of call-to-action, use a Button.`}</li>
          <li parentName="ul">{`For labels that don’t appear in multiples and in a group, consider using simple body text or a
header.`}</li>
          <li parentName="ul">{`If you have more content than a simple label to display, consider using simple body text or a
header.`}</li>
          <li parentName="ul">{`If you need to switch between related content, use Tabs or Segmented Controls.`}</li>
          <li parentName="ul">{`For any external navigation use a hyperlink.`}</li>
        </ul>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <p>{`The component is defined as `}<inlineCode parentName="p">{`struct Pill`}</inlineCode>{` with internal types of `}<inlineCode parentName="p">{`enum Variant`}</inlineCode>{`, `}<inlineCode parentName="p">{`enum Indication`}</inlineCode>{`,
and `}<inlineCode parentName="p">{`enum Size`}</inlineCode>{`.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct Pill: View
  public enum Variant: Hashable {
    case textOnly(_ title: String)
    case avatar(title: String, config: AvatarConfig)
  }

  public enum Indication {
    case \`default\`
    case removable
    case removableFiltered
    case additive
    case checkmark
    case counter(_ count: Int)
    case nonInteractive
  }

  public enum Size: String, CaseIterable {
    case medium // default
    case small
  }
`}</code></pre>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`  public init(
      featureData: FeatureMetricsData,
      variant: Variant,
      indication: Indication,
      size: Size = .medium,
      localizer: LocalizationAdapting,
      action: @escaping () -> Void = {}
  )
`}</code></pre>
        <h4 {...{
          "id": "parameters"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default values`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`featureData`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The feature name/context and the screen ID in which the component appears.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`variant`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The content variant of the pill.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`.textOnly`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`indication`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of visual indication (add, remove, etc.) for the pill.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`.default`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The visual size of the pill. Default is medium.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`.medium`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The localizer to use for accessibility labels.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`action`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The action to perform when the pill is tapped. There is no action for NonInteractive indication.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
        <p>{`Use the `}<inlineCode parentName="p">{`.disabled`}</inlineCode>{` method on the view in order to get the disabled appearance.`}</p>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`If content exceeds the max-width, an ellipses should appear at the end of the text to communicate
truncation.`}</li>
          <li parentName="ul">{`Pills must exceed a 3:1 contrast ratio against the background color the Pill is placed on. See
`}<a parentName="li" {...{
              "href": "https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=1411#non-text-contrast"
            }}>{`non-text contrast requirement`}</a>{`.`}</li>
          <li parentName="ul">{`An additional 4dp of tappable top and bottom padding are added to ensure a minimum tap target area
of 48dp.`}</li>
          <li parentName="ul">{`X icons in removable pills have a 48dp touch target that removes the pill from the UI.`}</li>
          <li parentName="ul">{`Pill names should be distinct from one another. Avoid using many Pills on the same page with
similar names as this places additional burden on users to understand the context of each pill.`}</li>
          <li parentName="ul">{`Screen readers must announce the entire Pill Label, regardless of width or truncation.`}</li>
        </ul>
        <p>{`For more accessibility considerations, refer to the `}<a parentName="p" {...{
            "href": "/guidelines/accessibility/overview"
          }}>{`Accessibility Guide`}</a>{`.`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Keep pill labels short and concise.`}</li>
          <li parentName="ul">{`Max-width of the pill is defined by the width of its parent container plus padding.`}</li>
          <li parentName="ul">{`All interactive pills will display an ellipses to communicate truncated content.`}</li>
          <li parentName="ul">{`Non-interactive pills should never truncate and instead wrap to multiple lines.`}</li>
        </ul>
        <p>{`When writing content for Pill Labels, refer to our
`}<a parentName="p" {...{
            "href": "/guidelines/content/ui-text/field-labels"
          }}>{`Content Style Guide`}</a>{`.`}</p>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?type=design&node-id=7263-3942&mode=design&t=bxuNrp4RWhbBNdjx-11" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/78d8a3d40ad98a996583a90fd1e717ad/0ddab/pills-anatomy-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAACE4AAAhOAFFljFgAAAAoklEQVR42n2QywrCMBBF/f//ciG6sBtbUws+SjelFPqgzSTNMX0IVdFLhjD3Zg5hNnzKufnOKyia2brmYIe33BU1bnyznvHafAE78flAE6W04YMR024VTVnRac3gwa1oapXRh9kf4GIOuwSbFkQnRRwndL2gDiXBMeKWpvTaEKozwf5OdqnHwen8/qEHj7kWwRjjW4eI8SVYa5deJs+81rDSE2F8N5K9tTayAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Pill's anatomy ",
                "title": "Image of a Pill's anatomy ",
                "src": "/static/78d8a3d40ad98a996583a90fd1e717ad/50383/pills-anatomy-mobile.png",
                "srcSet": ["/static/78d8a3d40ad98a996583a90fd1e717ad/1efb2/pills-anatomy-mobile.png 370w", "/static/78d8a3d40ad98a996583a90fd1e717ad/50383/pills-anatomy-mobile.png 740w", "/static/78d8a3d40ad98a996583a90fd1e717ad/0ddab/pills-anatomy-mobile.png 2220w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Background and border holds all elements within a Pill.`}</li>
          <li parentName="ol"><strong parentName="li">{`Label:`}</strong>{` Describes the Pill meaning or input, ideally a single word or two.`}</li>
          <li parentName="ol"><strong parentName="li">{`Leading Area (Optional):`}</strong>{` An avatar or flag displayed before the label.`}</li>
          <li parentName="ol"><strong parentName="li">{`Trailing Area (Optional):`}</strong>{` Icons placed after the label are meant to convey the intended
interaction of the pill, with the exception of the Count Pills. Trailing icon can be X, check,
plus or counter.`}</li>
        </ol>
        <h2 {...{
          "id": "interaction-states-1"
        }}>{`Interaction States`}</h2>
        <p>{`Pills support Inactive, Pressed, and Disabled States.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/974da2c2f7d8e6374e8989e0bc65e7a5/922e6/pills-states-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a Pill's states",
                "title": "Image of a Pill's states",
                "src": "/static/974da2c2f7d8e6374e8989e0bc65e7a5/50383/pills-states-mobile.png",
                "srcSet": ["/static/974da2c2f7d8e6374e8989e0bc65e7a5/1efb2/pills-states-mobile.png 370w", "/static/974da2c2f7d8e6374e8989e0bc65e7a5/50383/pills-states-mobile.png 740w", "/static/974da2c2f7d8e6374e8989e0bc65e7a5/922e6/pills-states-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "variations-1"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "default-1"
        }}>{`Default`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b76993334f1c2b4579c743ba2a88e0c/922e6/pills-default-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a default pill",
                "title": "Image of a default pill",
                "src": "/static/2b76993334f1c2b4579c743ba2a88e0c/50383/pills-default-mobile.png",
                "srcSet": ["/static/2b76993334f1c2b4579c743ba2a88e0c/1efb2/pills-default-mobile.png 370w", "/static/2b76993334f1c2b4579c743ba2a88e0c/50383/pills-default-mobile.png 740w", "/static/2b76993334f1c2b4579c743ba2a88e0c/922e6/pills-default-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`By default a Pill is interactive, however the resulting interaction may vary based on the location
or context of the pill. For example, Pills navigate a user to more details related to the label of
the pill. Pills within prompts or inputs open the prompt allowing further interaction.`}</p>
        <h3 {...{
          "id": "size-1"
        }}>{`Size`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c318def9bbc18d834bd35b7fe6cf2123/922e6/pills-size-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a the different pill sizes",
                "title": "Image of a the different pill sizes",
                "src": "/static/c318def9bbc18d834bd35b7fe6cf2123/50383/pills-size-mobile.png",
                "srcSet": ["/static/c318def9bbc18d834bd35b7fe6cf2123/1efb2/pills-size-mobile.png 370w", "/static/c318def9bbc18d834bd35b7fe6cf2123/50383/pills-size-mobile.png 740w", "/static/c318def9bbc18d834bd35b7fe6cf2123/922e6/pills-size-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "medium-1"
        }}>{`Medium`}</h4>
        <p>{`This is the default size for pills and should be used within all inputs where pills are utilized. An
additional 4pt of tappable top and bottom margins are added to maintain a 48pt tap target.`}</p>
        <h4 {...{
          "id": "small-1"
        }}>{`Small`}</h4>
        <p>{`The small pill is used for tasks that are supplementary to the main task. For example, displaying
filtered parameters in relation to primary content. This stage of filtering is supplementary to the
main task. The small size pill variant is used to reinforce the hierarchy of the page by adding
emphasis to more important elements. Small pills maintain an additional 8pt top and bottom margin to
ensure a 48pt tap target.`}</p>
        <h3 {...{
          "id": "filtered-1"
        }}>{`Filtered`}</h3>
        <p>{`Filtered Pills are the activated version of a pill. These pills are differentiated from default
pills by the addition of a checkmark or X icon, depending on the use case.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/481f1a4801f098883b0a919efb66d175/922e6/pills-filtered-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of filtered pills",
                "title": "Image of filtered pills",
                "src": "/static/481f1a4801f098883b0a919efb66d175/50383/pills-filtered-mobile.png",
                "srcSet": ["/static/481f1a4801f098883b0a919efb66d175/1efb2/pills-filtered-mobile.png 370w", "/static/481f1a4801f098883b0a919efb66d175/50383/pills-filtered-mobile.png 740w", "/static/481f1a4801f098883b0a919efb66d175/922e6/pills-filtered-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`The checkmark is meant to be used when all filters are displayed alongside each other, with the
ability to quickly turn a filter on or off. The checkmark identifies the active filters. The
filtered pills with the X are meant to be used when displaying only those active filters. Activation
of filters would typically be a part of a larger flow. The X allows the user to quickly remove the
active filter resulting in the pill disappearing from the screen.`}</p>
        <h3 {...{
          "id": "leading-area-1"
        }}>{`Leading Area`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/83d1a582fe966a2a89ef00eaea0ed552/922e6/pills-leading-area-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of leading area options for pills",
                "title": "Image of leading area options for pills",
                "src": "/static/83d1a582fe966a2a89ef00eaea0ed552/50383/pills-leading-area-mobile.png",
                "srcSet": ["/static/83d1a582fe966a2a89ef00eaea0ed552/1efb2/pills-leading-area-mobile.png 370w", "/static/83d1a582fe966a2a89ef00eaea0ed552/50383/pills-leading-area-mobile.png 740w", "/static/83d1a582fe966a2a89ef00eaea0ed552/922e6/pills-leading-area-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`All leading elements are intended to be descriptive, helping support the label. Do not rely on the
leading element to indicate the interaction behavior. Trailing elements are for interaction
behavior.`}</p>
        <h4 {...{
          "id": "avatar-1"
        }}>{`Avatar`}</h4>
        <p>{`A XXS avatar is displayed before the label.`}</p>
        <h4 {...{
          "id": "flag-1"
        }}>{`Flag`}</h4>
        <p>{`Coming Soon…`}</p>
        <h3 {...{
          "id": "trailing-area-1"
        }}>{`Trailing Area`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/63786a0b2209df13c8e3d7da80abdc44/922e6/pills-trailing-area-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of trailing options for pills",
                "title": "Image of trailing options for pills",
                "src": "/static/63786a0b2209df13c8e3d7da80abdc44/50383/pills-trailing-area-mobile.png",
                "srcSet": ["/static/63786a0b2209df13c8e3d7da80abdc44/1efb2/pills-trailing-area-mobile.png 370w", "/static/63786a0b2209df13c8e3d7da80abdc44/50383/pills-trailing-area-mobile.png 740w", "/static/63786a0b2209df13c8e3d7da80abdc44/922e6/pills-trailing-area-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Elements displayed after the label are meant to convey the expected interaction of the pill, with
the exception of the count variant.`}</p>
        <h4 {...{
          "id": "x-icon-1"
        }}>{`X Icon`}</h4>
        <p>{`These Pills display an X icon after the label conveying they can be removed from the page. If a Pill
is to be removed, there should always be a way for the user to add the Pill again. These should
never be used for destructive actions`}</p>
        <h4 {...{
          "id": "plus-icon-1"
        }}>{`Plus Icon`}</h4>
        <p>{`Pills with the plus icon are used to add items to a parent container. They can also be used in
filtering, conveying the filter can be activated.`}</p>
        <h4 {...{
          "id": "check-icon-1"
        }}>{`Check Icon`}</h4>
        <p>{`Pills with a checkmark are only used with filtered pills. The checkmark conveys the filter
attributed to the pill is active or enabled.`}</p>
        <h4 {...{
          "id": "count-1"
        }}>{`Count`}</h4>
        <p>{`Count pills display a number at the end of the pill. This number directly correlates with the pill
label. While the number can be as large as possible the count within the pill has a limit of 99, any
number larger than that will be displayed as 99+.`}</p>
        <h3 {...{
          "id": "non-interactive-1"
        }}>{`Non-Interactive`}</h3>
        <p>{`A non-interactive element used to display information about an object.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d4919dc664481cd575f95225562f6017/922e6/pills-non-interactive-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "12.432432432432433%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of a non-interactive pill",
                "title": "Image of a non-interactive pill",
                "src": "/static/d4919dc664481cd575f95225562f6017/50383/pills-non-interactive-mobile.png",
                "srcSet": ["/static/d4919dc664481cd575f95225562f6017/1efb2/pills-non-interactive-mobile.png 370w", "/static/d4919dc664481cd575f95225562f6017/50383/pills-non-interactive-mobile.png 740w", "/static/d4919dc664481cd575f95225562f6017/922e6/pills-non-interactive-mobile.png 2140w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Non-interactive pills do not truncate and instead wrap to multiple lines; however, try to keep Pill
text as succinct as possible - between 2-7 words or wrap no more than 2 lines.`}</p>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <p>{`Pills are used to visually label objects on a page for quick recognition. They’re offered as both
read-only and interactive elements. They allow users to input a section, filter, or label
information to help with scanning and organization.`}</p>
        <ul>
          <li parentName="ul">{`Interactive Pills should always truncate, never wrapping to multiple lines.`}</li>
          <li parentName="ul">{`Non-interactive Pills should not truncate and can wrap to multiple lines.`}</li>
          <li parentName="ul">{`The max-width of a pill is 600pt/dp, or up to the screen margins of the device.`}</li>
          <li parentName="ul">{`Pill groups can stack to multiple lines when necessary.`}</li>
          <li parentName="ul">{`Use pills in moderation, as they can increase the visual noise of a page.`}</li>
          <li parentName="ul">{`Ensure a minimum tap target height of 48dp to ensure easy interaction. This means adding an
additional 4dp of tappable top and bottom padding to the medium sized Pills and 8dp for small
Pills.`}</li>
        </ul>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a minimum tap target of 48dp for removal of the Pill." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/31e95bffe48e0f5fd2a143f76df7a208/8ce52/component-do-taptarget-pills-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Removable Pill with a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Removable Pill with a minimum tap target of 48dp.",
                    "src": "/static/31e95bffe48e0f5fd2a143f76df7a208/8ce52/component-do-taptarget-pills-mobile.png",
                    "srcSet": ["/static/31e95bffe48e0f5fd2a143f76df7a208/1efb2/component-do-taptarget-pills-mobile.png 370w", "/static/31e95bffe48e0f5fd2a143f76df7a208/8ce52/component-do-taptarget-pills-mobile.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use a tap target height smaller than 48dp, even if the Close Icon might appear smaller visually." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "681px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/e5400295c1a6bfa25f6a02ad1bc11564/8ce52/component-dont-taptarget-pills-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "64.5945945945946%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating a Link Pill without a minimum tap target of 48dp.",
                    "title": "Image demonstrating a Link Pill without a minimum tap target of 48dp.",
                    "src": "/static/e5400295c1a6bfa25f6a02ad1bc11564/8ce52/component-dont-taptarget-pills-mobile.png",
                    "srcSet": ["/static/e5400295c1a6bfa25f6a02ad1bc11564/1efb2/component-dont-taptarget-pills-mobile.png 370w", "/static/e5400295c1a6bfa25f6a02ad1bc11564/8ce52/component-dont-taptarget-pills-mobile.png 681w"],
                    "sizes": "(max-width: 681px) 100vw, 681px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <ul>
          <li parentName="ul">{`Account for this interaction buffer by spacing multiple pills with 8dp padding between, above, and
below each pill in a group.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To visually label applied inputs.`}</li>
          <li parentName="ul">{`To aid selection of inputs`}</li>
          <li parentName="ul">{`To imply further interaction on individual items within groups`}</li>
          <li parentName="ul">{`To visually show filtered parameters`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`For static labels that communicate a status, use a Status Indicator.`}</li>
          <li parentName="ul">{`For any sort of call-to-action, use a Button.`}</li>
          <li parentName="ul">{`For labels that don’t appear in multiples and in a group, consider using simple body text or a
header.`}</li>
          <li parentName="ul">{`If you have more content than a simple label to display, consider using simple body text or a
header.`}</li>
          <li parentName="ul">{`If you need to switch between related content, use Tabs or Segmented Controls.`}</li>
          <li parentName="ul">{`For any external navigation use a hyperlink.`}</li>
        </ul>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition-1"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun PillUiComponent(
    modifier: Modifier = Modifier,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    config: PillConfig
) {
`}</code></pre>
        <h4 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier to be applied to the PillUiComponent`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`interactionSource`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The MutableInteractionSource representing the stream of Interactions for this LinkPillUiComponent. You can create and pass in your own remembered MutableInteractionSource if you want to observe Interactions and customize the appearance / behavior of this LinkPillUiComponent in different Interactions.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`pillConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}{`[PillConfig]`}{` representing the configuration of the Pill.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "pillconfig"
        }}>{`PillConfig`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`data class PillConfig(
    val pillText: String,
    val onClick: (() -> Unit)? = null,
    val pillType: PillType,
    val size: PillSize = PillSize.Medium,
    val onClickLabel: String? = null
)
`}</code></pre>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`pillText`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text to be shown in the pill`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClick`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Will be called when the user taps the Pill.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`pillType`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Determines which variant of the `}{`[PillUiComponent]`}{` will be rendered. Variants: Link, Additive, Removable, Checked, NonInteractive`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Determines the size of the `}{`[PillUiComponent]`}{`. Variants: Medium, Small`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClickLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The action type to be read(as "Double tap to activate") by TalkBack when the user taps the Pill, default is "activate" for default pill, "add" for additive pill, "remove" for removable pill, "toggle" for checked pill.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "link-pill-type"
        }}>{`Link Pill Type`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`data class Link(
    val state: PillState.Interaction = PillState.Interaction(),
    val leadingConfig: PillLeadingConfig = PillLeadingConfig.None,
    val hasCounter: Boolean = false,
    val count: Int = 0
)
`}</code></pre>
        <h4 {...{
          "id": "parameters-2"
        }}>{`Parameters`}</h4>
        <p>{`All undocumented parameters are native Android Compose types that can be found in the developer
documentation.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`state`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The state of the pill. This allows for enabling, disabling states.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`leadingConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The leading config of the pill. This allows for adding an avatar to the pill.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`hasCounter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Determines if the pill has a counter.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`count`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The integer value to be shown in the counter.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "additive-pill-type"
        }}>{`Additive Pill Type`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`data class Additive(
    val state: PillState.Interaction = PillState.Interaction(),
    val leadingConfig: PillLeadingConfig = PillLeadingConfig.None
)
`}</code></pre>
        <h4 {...{
          "id": "parameters-3"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`state`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The state of the pill. This allows for enabling, disabling states.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`leadingConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The leading config of the pill. This allows for adding an avatar to the pill.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "removable-pill-type"
        }}>{`Removable Pill Type`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`data class Removable(
    val state: PillState = PillState.Interaction(),
    val leadingConfig: PillLeadingConfig = PillLeadingConfig.None,
    val isPlaceholder: Boolean = false
)
`}</code></pre>
        <h4 {...{
          "id": "parameters-4"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`state`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The state of the pill. This allows for enabling, disabling states.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`leadingConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The leading config of the pill. This allows for adding an avatar to the pill.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isPlaceholder`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Determines if the pill is a placeholder.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "checked-pill-type"
        }}>{`Checked Pill Type`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`data class Checked(
    private val state: PillState.Filtered = PillState.Filtered,
    val leadingConfig: PillLeadingConfig = PillLeadingConfig.None
)
`}</code></pre>
        <h4 {...{
          "id": "parameters-5"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`state`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The state of the pill, only Filtered state is allowed on Checked Pill.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`leadingConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The leading config of the pill. This allows for adding an avatar to the pill.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "non-interactive-pills"
        }}>{`Non-Interactive Pills`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`object NonInteractive : PillType()
`}</code></pre>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`If content exceeds the max-width, an ellipses should appear at the end of the text to communicate
truncation.`}</li>
          <li parentName="ul">{`Pills must exceed a 3:1 contrast ratio against the background color the Pill is placed on. See
`}<a parentName="li" {...{
              "href": "https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=1411#non-text-contrast"
            }}>{`non-text contrast requirement`}</a>{`.`}</li>
          <li parentName="ul">{`An additional 4dp of tappable top and bottom padding are added to ensure a minimum tap target area
of 48dp.`}</li>
          <li parentName="ul">{`X icons in removable pills have a 48dp touch target that removes the pill from the UI.`}</li>
          <li parentName="ul">{`Pill names should be distinct from one another. Avoid using many Pills on the same page with
similar names as this places additional burden on users to understand the context of each pill.`}</li>
          <li parentName="ul">{`Screen readers must announce the entire Pill Label, regardless of width or truncation.`}</li>
        </ul>
        <p>{`For more accessibility considerations, refer to the `}<a parentName="p" {...{
            "href": "/guidelines/accessibility/overview"
          }}>{`Accessibility Guide`}</a>{`.`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Keep pill labels short and concise.`}</li>
          <li parentName="ul">{`Max-width of the pill is defined by the width of its parent container plus padding.`}</li>
          <li parentName="ul">{`All interactive pills will display an ellipses to communicate truncated content.`}</li>
          <li parentName="ul">{`Non-interactive pills should never truncate and instead wrap to multiple lines.`}</li>
        </ul>
        <p>{`When writing content for Pill Labels, refer to our
`}<a parentName="p" {...{
            "href": "/guidelines/content/ui-text/field-labels"
          }}>{`Content Style Guide`}</a>{`.`}</p>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      